import './App.scss';
import Layout from './Layout';

function App() {
  return ( 
    <>
    <div className='app'>
      <Layout/>
    </div>
</>
  );
}

export default App;
