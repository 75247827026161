import React from "react";
import FacebookLogo from '../../Assets/Logo/FaLogo1.png'
import InstagramLogo from '../../Assets/Logo/InLogo1.png'
import LinkedinLogo from '../../Assets/Logo/LiLogo1.png'
import { Link } from "react-router-dom";
import LogoMail from '../../Assets/Logo/Logo-Mail.png'
import LogoTel from '../../Assets/Logo/Logo-Tel.png'
import './Footer.scss'
import GoogleMapComponent from "../GoogleMap/GoogleMap";

function Footer() {

	return (
    <div className="footer-container">
      <div className="flexbox-container">
        <div className="container-item-1">
          <ul>
            <p className="footer-title">
              Suivez-<span className="">nous</span>
            </p>
            <div className="container-main-logo">
              <Link to="https://www.instagram.com/orgacompte/?hl=fr">
                <img
                  className="container-logo"
                  src={InstagramLogo}
                  alt="Instagram Logo"
                />
              </Link>
              <Link to="https://www.facebook.com/impression.orgacompte">
                <img
                  className="container-logo"
                  src={FacebookLogo}
                  alt="Facebook Logo"
                />
              </Link>
              <Link to="https://fr.linkedin.com/in/orgacompte-joelle-594a749a">
                <img
                  className="container-logo"
                  src={LinkedinLogo}
                  alt="Linkedin Logo"
                />
              </Link>
            </div>
            <p className="">
              116 imp. Juvénal
              <br />
              30900 Nîmes
              <br />
              France
              <br />
              <img className="container-icon" src={LogoTel} alt="tel-icon" /> :
              +33 (0)4 66 38 66 46
              <br />
              <img
                className="container-icon"
                src={LogoMail}
                alt="email-icon"
              />{" "}
              : contact@orgacompte.fr
              <br />
            </p>
          </ul>
        </div>
        <div className="container-item-2">
          <GoogleMapComponent />
            <Link to="/Politique-De-Confidentialité">
              Politique De Confidentialité
            </Link>
        </div>
      </div>
      <div className="copyright">© 2023 Tous droit reservé Orgacompte.fr </div>
    </div>
  );
}

export default Footer;