const Speciaux = [
  {
    title: "Artistes",
    description:
      "Pour les Artistes: toile canvas, impression en relief, papier, tissus, bois, alu, carton etc...",
    icon: require("../../../Assets/Materiaux/Speciaux/Canvas.png"),
  },
  {
    title: "Electrostatique",
    description: "Electrostatique, sans colle, repositionnable , pour vitres",
    icon: require("../../../Assets/Materiaux/Speciaux/electrostatique.png"),
  },
  {
    title: "Magnétique",
    description: "Magnétique, en rouleau de 300 à 800 microns",
    icon: require("../../../Assets/Materiaux/Speciaux/Magnétique.png"),
  },
  {
    title: "Ferreux",
    description: "Ferreux",
    icon: require("../../../Assets/Materiaux/Speciaux/ferreux.png"),
  },
  {
    title: "Retroreflechissant",
    description: "Retroreflechissant: prismatique classe 1",
    icon: require("../../../Assets/Materiaux/Speciaux/Retroreflechissant.png"),
  },
  {
    title: "Tacko",
    description: "Tacko: microventouse sans colle, repositionnable",
    icon: require("../../../Assets/Materiaux/Speciaux/Tacko.png"),
  },
  {
    title: "Photo luminescents",
    description: "Photo Luminescents classe C",
    icon: require("../../../Assets/Materiaux/Speciaux/photoluminescent.png"),
  },
];

export default Speciaux;
