import React, { useEffect, useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import logo from "../../Assets/Logo/Logo.png";
import { useNavigate, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import ContactButton from "../Button/Contact/ContactButton";

const Header = ({ setButtonPopUp, buttonPopUp }) => {
  let navigate = useNavigate();
  const location = useLocation();
  const [hamburger, setHamburger] = useState(false);
  const [, setHideContact] = useState(window.innerWidth > 1024 ? false : true);

  function isActualPage(string) {
    if (string === "/") return location.pathname.length < 2 && " active";
    return location.pathname.includes(string) ? " active" : "";
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth > 1024) {
        setHamburger(false);
      }
  
      if (window.innerWidth < 1024) {
        setHideContact(true);
      } else {
        setHideContact(false);
      }
    });
  });

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ delay: 0 }}
      className="header-container"
    >
      {!hamburger && <Link className={'fadeIn'} to="/">
        <img className="header-logo" src={logo} alt="Orgacompte logo" />
      </Link>}
      {window.innerWidth > 1024 && <div className='fadeIn'>
        <ContactButton setButtonPopUp={() => setButtonPopUp(true)} />
      </div>}
      <div className={`navbar ${hamburger && "open"}`}>
        <div className={`navbar ${hamburger && "open"}`}>
        <div onClick={() => navigate("/")} className="navContainer">
          <div className='navBlock'>
            <div className={"nav navblue " + isActualPage("/")}>
              <p className="navLink">Accueil</p>
            </div>
            <div className="navShadow" />
          </div>
        </div>
        <div onClick={() => navigate("/Services")} className="navContainer">
          <div className='navBlock'>
            <div className={"nav navpink " + isActualPage("/Services")}>
              <p className="navLink">Services</p>
            </div>
            <div className="navShadowReverse" />
          </div>
        </div>
        <div onClick={() => navigate("/Matieres")} className="navContainer">
        <div className='navBlock'>
            <div className={"nav navyellow " + isActualPage("/Matieres")}>
              <p className="navLink">Matieres</p>
            </div>
            <div className="navShadow" />
          </div>
        </div>
        <div onClick={() => navigate("/Realisations")} className="navContainer">
          <div className='navBlock'>
            <div className={"nav navblack " + isActualPage("/Realisations")}>
              <p className="navLink">Creation</p>
            </div>
            <div className="navShadowReverse" />
          </div>
        </div>
        </div>
        {hamburger && <div className='displayBlock'>
          <ContactButton hamburger="true" setButtonPopUp={() => setButtonPopUp(true)} />
        </div>}
      </div>
      <div className="hamburgerMenu" onClick={() => setHamburger(!hamburger)}>
        <div className={`hamburgerBar top ${hamburger && "open"}`} />
        <div className={`hamburgerBar mid ${hamburger && "open"}`} />
        <div className={`hamburgerBar bot ${hamburger && "open"}`} />
      </div>
    </motion.div>
  );
};

export default Header;
