import React from "react";
import "./Presentation.scss";
import Arizona from "../../Assets/Pictures/Arizona1.png";
import LogoCercle from "../../Assets/Logo/Logo-Cercle.png";
import Paint from "../../Assets/Pictures/Paint.png";

function Presentation() {
  return (
    <div className="main-container">
      <div className="item-1">
        <div className="container-arizona">
          <img
            className="arizona"
            src={Arizona}
            alt="Girl in a jacket"
            width="500"
            height="500"
          />
        </div>
        <div className="inner-1">
          <p className="p">
            <h1>Présentation</h1>" Grâce à notre parc de machines constamment
            mis à jour, nous sommes en mesure de vous fournir des impressions
            sur une variété de supports et de vous accompagner dans vos projets
            de communication visuelle."
          </p>
        </div>
      </div>
      <img className="paint" src={Paint} alt="Girl in a jacket" />
      <div className="item-2">
        <div className="a-propos-text">
          <img className="logo-cercle" src={LogoCercle} alt="logo-cercle" />

          <span className="p">
            <h1>Historique</h1>
            "Fondé en 1981, Orgacompte est un atelier spécialisé dans
            l'impression numérique grand format et la reprographie. Nous sommes
            experts dans l'impression et la découpe de divers supports,
            accumulant ainsi une vaste expérience dans le domaine de la
            communication visuelle. Cette expertise nous permet de vous offrir
            des solutions adaptées à vos besoins, alliant à la fois des
            compétences techniques avancées et des approches innovantes."
          </span>
        </div>
      </div>
      {/* <img className="paint-2" src={Paint} alt="Girl in a jacket" /> */}
    </div>
  );
}

export default Presentation;
