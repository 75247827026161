import React from "react";
import "./PDC.scss";

function PDC() {
  return (
    <div className="container-pdc">
      <h1>Politique de confidentialité des données</h1>{" "}
      <p1>
        En visitant notre site Web et en utilisant nos services, vous comprenez
        et acceptez la façon dont nous traitons les données personnelles
        conformément à notre politique de confidentialité. Nous respectons votre
        vie privée et le droit de contrôler vos données personnelles. Nos
        principes directeurs sont simples. Nous allons être ouverts avec quelles
        données nous collectons et pourquoi. Veuillez prendre un moment pour les
        lire. C'est important !<br /> Orgacompte respecte votre vie privée et le
        droit de contrôler vos données personnelles. Nos principes directeurs
        sont simples. Nous serons ouverts à vous informer sur les données que
        nous collectons et pourquoi. Nous protégeons vos données comme si
        c'était notre plus grand secret commercial.
        <br /> <br /> ​ Nous nous engageons à prendre toutes dispositions
        nécessaires afin d’assurer la sécurité et la confidentialité de vos
        Données Personnelles, en conformité avec la réglementation en vigueur,
        et notamment le Règlement UE 2016/679 dit « RGPD » et la loi n° 78-17 du
        6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux
        libertés.
        <br />
        <br /> La politique de confidentialité de orgacompte décrit quelles
        données nous collectons, le but du traitement, comment vous pouvez
        contrôler vos propres données et comment nous contacter. La présente
        politique de confidentialité s'applique lorsque vous utilisez des
        applications, des pages Web et des services qui sont inclus dans une
        application ou sur un site Web géré par la Société.
        <br />
        <br /> Voici un résumé de ce à quoi vous pouvez vous attendre en
        visitant notre site Web :<br />
        <br />
        <li>
          Assurer la communication et/ou l’échange d’informations ainsi que la
          réponse à vos éventuelles demandes lorsque vous y avez préalablement
          et expressément consenti par l’envoi d’un formulaire de
          contact/candidature, d’un e-mail à une adresse générique, ou tout
          autre correspondance ;
        </li>
        <li>
          Traitement des renseignements personnels en les envoyant à des
          systèmes intégrés de courriel et de gestion de la relation client
          (CRM) à des fins de marketing et de relations clients.
        </li>
        <li>
          Nous sauvegardons les informations personnelles dans nos bases à des
          fins d'analyse.
        </li>
        <li>
          {" "}
          Votre comportement et votre activité sur le Web peuvent être utilisés
          à des fins de marketing comme le ciblage et l'analyse marketing.
        </li>{" "}
      </p1>
      <h1>Quelles données personnelles collectons-nous?</h1> Orgacompte
      recueille les informations principalement auprès de vous en tant que
      client. Des exemples de données personnelles que nous pourrions collecter
      sont :<br /> <br /> <li>Société</li>
      <li>Nom / Prénom</li> <li>Adresse E-mail</li> <li>Numéro de téléphone</li>
      <br />
      Orgacompte peut mettre à jour vos informations à partir d'autres dossiers
      publics si cela est nécessaire pour maintenir un bon entretien du
      référentiel de donnée.
      <br />
      <br /> Orgacompte enregistre les données personnelles en relation avec:
      <br />
      <br /> a) Notification d'un service offert par le biais des différentes
      plateformes offertes par la Société.
      <br />
      <br /> b) Lorsque vous entrez votre adresse e-mail ou votre numéro de
      téléphone portable sur le site. <br />
      <br />
      d) Si vous nous contactez par courriel, par téléphone, par nos sites Web
      et par les médias sociaux. <br />
      <br />
      e) Votre visite de sur nos sites Web et lors de l'accès à notre
      application, y compris, mais sans s' y limiter, les données de trafic, les
      données de localisation, les blogs et d'autres données de communication,
      telles que l'adresse IP, le type de périphérique, le système
      d'exploitation et le type de navigateur utilisé pour la visite.
      <br />
      <br /> Nous pouvons partager des informations avec d'autres sociétés qui
      traitent des données en notre nom afin d'exécuter nos services,
      c'est-à-dire l'analyse, la distribution ou d'autres services pour
      maintenir et appliquer nos conditions d'utilisation et de livraison.
      Cependant, le traitement des données personnelles garantit toujours une
      sécurité et une confidentialité élevées.
      <br />
      <br /> La société ne collabore qu'avec des partenaires qui traitent des
      données à caractère personnel au sein de l'UE/EEE ou avec des entreprises
      qui maintiennent le même niveau de protection que dans l'UE/EEE, par
      exemple en adhérant à ce que l'on appelle le Privacy Shield Agreement
      entre l'UE et les États-Unis.
      <br />
      <br />{" "}
      <h1>
        ​ Droit à l'oubli, stockage et divulgation des données personnelles
      </h1>
      Vous pouvez à tout moment retirer votre consentement en nous en faisant la
      demande via la fonction "contact"
      <br />
      <br />
      Le traitement est conforme à la législation en vigueur et signifie que les
      données personnelles ne sont pas conservées pendant une période plus
      longue que nécessaire aux fins du traitement. La Société conservera vos
      données personnelles tant que vous serez en relation commerciale avec
      nous. A des fins de marketing, les informations datant de plus de 4 ans ne
      sont pas utilisées. Cela implique que les données seront effacées
      lorsqu'elles ne sont plus pertinentes ou nécessaires à des fins d'analyse
      ou de marketing direct aux fins pour lesquelles elles ont été collectées.
      Certaines informations peuvent être conservées plus longtemps au besoin en
      raison d'autres exigences légales. Cependant, le traitement des données
      personnelles garantit toujours une sécurité et une confidentialité
      élevées.
      <br />
      <br /> <h1>Vos droits et vos choix</h1> Vous disposez des droits sur vos
      données personnelles : <br />
      <br />{" "}
      <li>Demander et de posséder une copie de vos données personnelles</li>
      <li>
        Demander la modification ou destruction de vos données sauvegardées.
      </li>{" "}
      La Société rectifiera, de sa propre initiative ou à l'initiative du
      client, les données jugées incorrectes. Vous pouvez également demander à
      tout moment que vos données soient effacées ou que leur utilisation soit
      limitée en nous contactant (voir la rubrique "Contact").
      <br />
      <br /> Si vous pensez que nous ne respectons pas vos droits, veuillez
      contacter Orgacompte ou contacter l'Autorité de Protection des Données de
      votre pays, à :<br />
      <br />
      http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_fr.
      <br />
      <br />
      htm. Dans le cas où notre site contient des liens vers des sites Web de
      tiers, des sites Web ou des documents publiés par des tiers, ces liens
      sont fournis à titre d'information seulement. Étant donné que la Société
      n' a aucun contrôle sur le contenu de ces sites Web ou de son matériel,
      nous ne sommes pas responsables de leur contenu. La Société n'est pas non
      plus responsable des dommages ou pertes qui pourraient résulter de
      l'utilisation de ces liens.
      <br />
      <br /> <h1>Comment nous contacter</h1> Pour plus d'informations sur la
      gestion des données personnelles ou si vous avez des questions, n'hésitez
      pas à nous contacter :<br />
      <br /> Orgacompte 116 imp. juvenal
      <br /> 30900 Nîmes
      <br />
      <br /> Téléphone : 04 66 38 66 46
      <br /> E-mail : contact@orgacompte.fr
    </div>
  );
}

export default PDC;
