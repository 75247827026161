const Rigides = [
  {
    title: "Akylux",
    description:
      "Akylux, panneau en polypropylène alvéolaire de 3.5mm à 10 mm, pour panneaux de chantier ",
    icon: require("../../../Assets/Materiaux/Rigides/Akylux.png"),
  },
  {
    title: "Aludibond",
    description:
      "Alu-Composite, panneau en aluminium de 3mm à 5mm, panneaux de chantier, enseigne, signalétique ect...",
    icon: require("../../../Assets/Materiaux/Rigides/Alu.png"),
  },
  {
    title: "Plexiglas",
    description:
      "Plexiglas, Transparent, translucide, opaque, couleurs, de 2mm à 20mm, pour enseigne, signalétique, décoration, ect...",
    icon: require("../../../Assets/Materiaux/Rigides/Plexis.png"),
  },
  {
    title: "Pvc",
    description:
      "Pvc, panneau en polyvinyle de 1mm à 30mm, pour enseigne, signalétique, décoration, habillage de façade, stand",
    icon: require("../../../Assets/Materiaux/Rigides/Pvc.png"),
  },
  {
    title: "Ecologique",
    description:
      "Ecologique, plastic recyclé, liège et Stadur de 3 mm à 10 mm, pour enseigne, signalétique, décoration",
    icon: require("../../../Assets/Materiaux/Rigides/Ecologique.png"),
  },
  {
    title: "Carton",
    description:
      "Carton, alveolaire, nid d'abeille, compact, de 0.6mm à 20mm, pour enseigne, signalétique, décoration",
    icon: require("../../../Assets/Materiaux/Rigides/Carton.png"),
  },
];

export default Rigides;
