import React from "react";
import "./Matiere.scss";
import PresentationMatiere from "../../Components/Matiere/PresentationMatiere.jsx";
import { motion } from "framer-motion";

function Matiere() {
  return (
    <motion.div
      className="main"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <PresentationMatiere />
    </motion.div>
  );
}

export default Matiere;
