import React, { useState, useEffect, useRef } from "react";

// import axios from "axios";

import Feed from "./Feed";

import "./instaFeed.scss";

const InstaFeeds = ({ ...props }) => {
  const [feeds, setFeedsData] = useState([]);
  const token = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;
  //use useRef to store the latest value of the prop without firing the effect
  const tokenProp = useRef(token);
  tokenProp.current = token;
  const url = `https://graph.instagram.com/me/media?fields=id,media_type,media_url,permalink,caption&limit=${props.limit}&access_token=${token}`;

  useEffect(() => {
    // this is to avoid memory leaks
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      const options = {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      };

      try {
        fetch(url, options)
          .then((response) => response.json())
          .then((data) => {
            setFeedsData(data.data);
          });
      } catch (err) {}
    }

    // manually call the fecth function
    fetchInstagramPost();

    return () => {
      // cancel pending fetch request on component unmount
      abortController.abort();
    };
  }, [props.limit, url]);

  return (
    <div className="container-instagram">
      {feeds.map((feed) => (
        <Feed key={feed.id} feed={feed} />
      ))}
    </div>
  );
};

export default InstaFeeds;
