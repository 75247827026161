import React from "react";
import { Link } from "react-router-dom";

const Feed = (props) => {
  const { id, caption, media_type, media_url, permalink} = props.feed;
  let post;

  switch (media_type) {
    case "VIDEO":
      post = (
        <div style={{height: "400px"}}>
          <video
            width="100%"
            height="100%"
            src={media_url}
            type="video/mp4"
            controls
            playsInline
          />
        </div>
      );
      break;
    case "CAROUSEL_ALBUM":
      post = (
        <Link
        to={permalink}
        >
          <img
            width="100%"
            height="400px"
            id={id}
            src={media_url}
            alt={caption}
          />
        </Link>
      );
      break;
    default:
      post = (
        <Link
        to={permalink}>
          <img
            width="100%"
            height="400px"
            id={id}
            src={media_url}
            alt={caption}
          />
        </Link>
      );
  }

  return <React.Fragment>{post}</React.Fragment>;
};

export default Feed;
