import React from "react";
import { useRef, useState } from "react";
import "./Contact.scss";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import swal from "sweetalert";

const Contact = () => {
  const [onChange, setOnChange] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.REACT_APP_EMAILJS_SERVICE_ID}`,
        `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`,
        form.current,
        `${process.env.REACT_APP_EMAILJS_API_PUBLIC_KEY}`
      )
      .then(
        (result) => {
          e.target.reset();
          swal("Email envoyer !", "Merci pour votre message");
        },
        (error) => {
          alert(error.text);
        }
      );
  };

  return (
    <form
      ref={form}
      name="form1"
      className="sendGrid-container"
      onSubmit={sendEmail}
    >
      <h1>Entrer en contact</h1>
      <input
        required
        className="custom-box"
        type="text"
        name="user_name"
        placeholder="Nom/Societe..."
      />
      <input
        required
        className="custom-box"
        type="email"
        name="user_email"
        placeholder="Email..."
      />
      <textarea
        required
        className="custom-box "
        cols="30"
        rows="10"
        type="text"
        name="message"
        placeholder="Entrez votre message..."
      />
      <div className="captcha">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={() => setOnChange(!onchange)}
        />
      </div>
      <button className={`sendgrid-button ${onChange && " display"}`}>
        Envoyer
      </button>
    </form>
  );
};

export default Contact;
