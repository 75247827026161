import React from "react";
import "./ContactButton.scss";

function ContactButton({ setButtonPopUp, hamburger = false }) {
  return (
    <div className="container-button">
      <button
        className={`button-contact ${hamburger && "absolut"}`}
        onClick={() => setButtonPopUp()}
      >
        Contact
      </button>
    </div>
  );
}

export default ContactButton;
