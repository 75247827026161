const Souples = [
  {
    title: "Bâche",
    description: "Bâche : enduite, microperforee ,economique de 450g à 540g",
    icon: require("../../../Assets/Materiaux/Souples/Bache.png"),
  },
  {
    title: "Papier",
    description: "Papier: FSC de 130g à 220 g, ou Outdoor",
    icon: require("../../../Assets/Materiaux/Souples/Papier.png"),
  },
  {
    title: "Papier peint",
    description: "Papier Peint, lisse ou structuré",
    icon: require("../../../Assets/Materiaux/Souples/PapierPeint.png"),
  },
  {
    title: "Tissus polyester",
    description: "Tissus polyester",
    icon: require("../../../Assets/Materiaux/Souples/Polyester.png"),
  },
  {
    title: "Polypro",
    description: "Polypro de 300 à 1200 Microns",
    icon: require("../../../Assets/Materiaux/Souples/polypro.png"),
  },
];

export default Souples;
