import React, { useState } from "react";
import "../../App.scss";
import "./PresentationMatiere.scss";
import Rigide from "../../Assets/Materiaux/Rigide.jpg";
import Souple from "../../Assets/Materiaux/Souple.jpg";
import Adhesive from "../../Assets/Materiaux/Adhesive.jpg";
import Special from "../../Assets/Materiaux/Special.jpeg";
import PopUp from "../PopUp";
import { Carousel } from "../Carousel/Carousel";
import {
  Rigides,
  Souples,
  Adhesives,
  Speciaux,
} from "../Carousel/MateriauxItems";


function PresentationMatiere() {
  const [buttonPopUp, setButtonPopUp] = useState("");
  const clickMatieres = (matiere) => {
    setButtonPopUp(matiere);
  };
  const switchPopUp = () => {
    switch (buttonPopUp) {
      case "Rigide":
        return <Carousel materiaux={Rigides} />;
      case "Souple":
        return <Carousel materiaux={Souples} />;
      case "Adhesive":
        return <Carousel materiaux={Adhesives} />;
      case "Special":
        return <Carousel materiaux={Speciaux} />;
      default:
        return "rien a afficher";
    }
  };
  return (
    <div className="item-container">
      <div onClick={() => clickMatieres("Rigide")} className="pointer first">
        <div
          className="pointe"
          style={{ "border-left": "20px solid #03bcff" }}
        />
        <h1 className="titre">Rigide</h1>
      </div>
      <img
        className="pics"
        onClick={() => clickMatieres("Rigide")}
        src={Rigide}
        alt="Rigide"
        width="300"
        height="250"
      />
      <div onClick={() => clickMatieres("Souple")} className="pointer second">
        <div
          className="pointe"
          style={{ "border-left": "20px solid #f50492" }}
        />
        <h1 className="titre">Souple</h1>
      </div>
      <img
        className="pics"
        onClick={() => clickMatieres("Souple")}
        src={Souple}
        alt="Rigide"
        width="300"
        height="250"
      />
      <div onClick={() => clickMatieres("Adhesive")} className="pointer third">
        <div
          className="pointe"
          style={{ "border-left": "20px solid #ffff08" }}
        />
        <h1 className="titre">Adhesive</h1>
      </div>
      <img
        className="pics"
        onClick={() => clickMatieres("Adhesive")}
        src={Adhesive}
        alt="Rigide"
        width="300"
        height="250"
      />
      <div onClick={() => clickMatieres("Special")} className="pointer fourth">
        <div
          className="pointe"
          style={{ "border-left": "20px solid #000000" }}
        />
        <h1 className="titre">Speciaux</h1>
      </div>
      <img
        className="pics"
        onClick={() => clickMatieres("Special")}
        src={Special}
        alt="Rigide"
        width="300"
        height="250"
      />
      <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp}>
        {switchPopUp()}
      </PopUp>
    </div>
  );
}

export default PresentationMatiere;
