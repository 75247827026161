import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "../../Templates";
import Materials from "../../Templates/Matiere";
import Services from "../../Templates/Services/Services";
import Realisations from "../../Templates/Realisations/Realisations";
import PDC from "../../Templates/Politique-De-Confidentialité/PDC.jsx";
import { AnimatePresence } from "framer-motion";

function AnimatedRoutes() {
  const location = useLocation();
  return (
    <div>
      <AnimatePresence>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Matieres" element={<Materials />} />
          <Route path="/Realisations" element={<Realisations />} />
          <Route path="/Politique-De-Confidentialité" element={<PDC />} />
        </Routes>
      </AnimatePresence>
    </div>
  );
}

export default AnimatedRoutes;
