import {React , useState} from 'react'
import './Layout.scss'
import Footer from './Components/Footer/Footer'
import Header from './Components/Header/Header'
import { BrowserRouter } from 'react-router-dom'
import PopUp from "./Components/PopUp";
import Contact from "./Components/Contact/Contact";
import AnimatedRoutes from './Components/AnimatedRoutes/AnimatedRoutes'

function Layout() {
  // const location = useLocation();
  
const [buttonPopUp, setButtonPopUp] = useState("");

  return (
    <BrowserRouter>
      <div className="layout">
        <Header setButtonPopUp = {() => setButtonPopUp(true)} buttonPopUp={buttonPopUp} />
          <AnimatedRoutes />
        <PopUp trigger={buttonPopUp} setTrigger={setButtonPopUp}>
          <Contact />
        </PopUp>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default Layout