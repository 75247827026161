const Adhesives = [
  {
    title: "Ultratack",
    description: "Ultratack, colle forte",
    icon: require("../../../Assets/Materiaux/Adhesif/Ultratack.png"),
  },
  {
    title: "Monomère, Polymère",
    description: "Monomère: durée de vie 3 ans, Polymère: durée de vie 5 ans, normal ou bubble free",
    icon: require("../../../Assets/Materiaux/Adhesif/AdhesifPolyMono.png"),
  },
  {
    title: "Repositionable",
    description: "Repositionnable",
    icon: require("../../../Assets/Materiaux/Adhesif/AdhesifReposi.png"),
  },
  {
    title: "Depoli",
    description: "Depoli",
    icon: require("../../../Assets/Materiaux/Adhesif/Depoli.png"),
  },
  {
    title: "Diffusant",
    description: "Diffusant",
    icon: require("../../../Assets/Materiaux/Adhesif/Diffusant.png"),
  },
  {
    title: "Antidérapant",
    description: "Antidérapant",
    icon: require("../../../Assets/Materiaux/Adhesif/AntiDerapent.png"),
  },
  {
    title: "Transparent",
    description: "Transparent",
    icon: require("../../../Assets/Materiaux/Adhesif/Transparent.png"),
  },
];

export default Adhesives;
