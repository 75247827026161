import React from 'react'
import InstaFeeds from '../../Components/Instagram/instaFeed'
import { motion } from "framer-motion";

function Realisations() {
  return (
    <motion.div
      className="main"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
        <h1>Nos Réalisations</h1>
      <InstaFeeds limit={12} />
    </motion.div>
  );
}

export default Realisations