import React from "react";
import "./Carousel.scss";

export const CarouselItem = ({ item, width }) => {
  return (
    <div className="carousel-item" style={{ width: width }}>
      <img className="carousel-img" src={item.icon} alt={item.title} />
      <div className="carousel-item-text">{item.description}</div>
    </div>
  );
};
