import React from "react";
import ReactPlayer from "react-player";
import "./ReactPlayer.scss";
import { motion } from "framer-motion";

function ReactPlayer1() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0 }}
      className="container"
    >
      <h1>Orgacompte Une histoire d'envie...</h1>
      <ReactPlayer
        className="react-player"
        url="https://www.youtube.com/watch?v=LkVZ89w_jwk"
        muted={true}
        playing={true}
        width={"100%"}
        controls={true}
      />
    </motion.div>
  );
}

export default ReactPlayer1;
