import React from "react";
import "./PresentationService.scss";
import { Repro1} from "../../Assets/Services"

function PresentationService() {
  return (
    <div className="main">
      <div className="container">
        <div className="container-service">
          <div className="text-imagerie">
            <h1 className="h1">L'imagerie</h1>
            <p className="p">
              <li>
                Impression tous supports sur une table à plat de 2,50 m x
                3,05 m, avec jet d'encre et séchage UV.
              </li>
              <br />

              <li>
                Impression sur support souple (en rouleau) jusqu'à 1,6 m de
                largeur, avec encre UVgel écologique.
              </li>

              <br />
              <li>
                Plastification à plat sur table à laminer de 1.58m de largeur.{" "}
                <br /> ainsi qu'en rouleau pour tous autres matériaux souples.
              </li>

              <br />
              <li>
                Table de découpe de 2 m x 3 m, équipée de plusieurs outils
                <br /> tels que couteau, fraise, oscillant, poinçon. Elle permet
                <br />
                la coupe multiforme, le rainage, le pliage
                <br /> et la découpe de précision.
              </li>

              <br />
              <li>
                Découpe laser sur table
                <br /> de 1 m x 0,7 m, offrant
                <br /> une découpe de
                <br /> précision sur
                <br /> diverses <br />
                matières.
              </li>
            </p>
          </div>
        </div>
        <div className="bandeau"></div>
        <div className="container-repro">
          <img className="pic" src={Repro1} alt="Imprimante" />
          <div className="text-repro">
            <h1 className="h2">La Reprographie et le scanner grand format</h1>
            <h4>
              Explorez l'art de la reproduction avec notre service de
              reprographie :
            </h4>{" "}
             La reprographie englobe toutes les techniques de reproduction
            de plans et de documents. Notre expertise dans ce domaine vous
            garantit des reproductions de qualité, que ce soit pour des plans
            architecturaux, des documents techniques, ou toute autre nécessité
            de reproduction. <br />
            <br /> <h4>Numérisation en grand format:</h4> Notre scanner
            grand format est l'outil idéal pour la numérisation de documents
            jusqu'à une largeur de 1,5 mètre. Cette capacité étendue permet la
            numérisation de grands formats, préservant les détails et la clarté
            de vos documents originaux. <br />
            <br /> Confiez-nous vos besoins de reprographie et de numérisation
            grand format, et bénéficiez de notre engagement envers la qualité et
            la précision.
          </div>
        </div>
      </div>
    </div>
  );
}


export default PresentationService;
