import React from "react";
import "./PopUp.scss";

function PopUp(props) {

  function outsideClick () {
    props.setTrigger(false)
  }

  return props.trigger ? (
    <div className="popup">
      <div className='popupBackground' onClick={outsideClick}/>
      <div className="popup-inner" >
        <button
          className="close-btn"
          onClick={() => props.setTrigger(false)}
        >
          {" "}
          <span className="material-symbols-outlined">close</span>
        </button>
        {props.children}
      </div>
    </div>
  ) : (
    ""
  );
}

export default PopUp;
