import React from "react";
import ReactPlayer1 from "../Components/React Player/ReactPlayer";
import Presentation from "../Components/Presentation/Presentation";
import "./Home.scss";
import { motion } from "framer-motion";
import InstaFeeds from "../Components/Instagram/instaFeed";

function Home() {
  return (
    <motion.div
      className="main"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <ReactPlayer1 />

      <Presentation />
      <InstaFeeds limit={3} />
    </motion.div>
  );
}

export default Home;
